<div class="banner"></div>
<div class="portfolio-area portfolio-three white-bg section-padding clearfix">
    <div class="container">
            <div class="col-xs-12">
                <div class="portfolio-menu hover-1 text-center">
                    <ul class="clearfix mb-40">
                        <li data-filter="*" class="active">All</li>
                        <li data-filter=".web" class="">Web</li>
                        <li data-filter=".design" class="">Design</li>
                        <li data-filter=".new" class="">new</li>
                        <li data-filter=".print" class="">Print</li>
                        <li data-filter=".work3d" class="">3d work</li>
                    </ul>
                </div>
                <div class="portfolio-grid fitRows-grid hover-1" style="position: relative; height: 1540.97px;">
                    <div class="grid-item work3d print percent-33" style="position: absolute; left: 0px; top: 0px;">
                        <div class="single-portfolio overlay">
                            <img src="assets/img/portfolio/three/1.jpg" alt="MiniPo">
                            <div class="zoom-icon">
                                <a class="venobox vbox-item" data-gall="gall-img" href="assets/img/portfolio/three/1.jpg">
                                    <i class="zmdi zmdi-fullscreen"></i>
                                </a>
                                <a href="portfolio-details-7.html">
                                    <i class="zmdi zmdi-link"></i>
                                </a>
                            </div>
                        </div>
                        <div class="project-title pl-30 ptb-30">
                            <a href="portfolio-details-7.html"><h4 class="mb-5">Portfolio Item Title</h4></a>
                            <p>Print, Branding</p>
                            <hr class="line">
                        </div>
                    </div>
                    <div class="grid-item web print new percent-33" style="position: absolute; left: 396px; top: 0px;">
                        <div class="single-portfolio overlay">
                            <img src="assets/img/portfolio/three/2.jpg" alt="MiniPo">
                            <div class="zoom-icon">
                                <a class="venobox vbox-item" data-gall="gall-img" href="assets/img/portfolio/three/2.jpg">
                                    <i class="zmdi zmdi-fullscreen"></i>
                                </a>
                                <a href="portfolio-details-7.html">
                                    <i class="zmdi zmdi-link"></i>
                                </a>
                            </div>
                        </div>
                        <div class="project-title pl-30 ptb-30">
                            <a href="portfolio-details-7.html"><h4 class="mb-5">Portfolio Item Title</h4></a>
                            <p>Print, Branding</p>
                            <hr class="line">
                        </div>
                    </div>
                    <div class="grid-item work3d design new percent-33" style="position: absolute; left: 793px; top: 0px;">
                        <div class="single-portfolio overlay">
                            <img src="assets/img/portfolio/three/3.jpg" alt="MiniPo">
                            <div class="zoom-icon">
                                <a class="venobox vbox-item" data-gall="gall-img" href="assets/img/portfolio/three/3.jpg">
                                    <i class="zmdi zmdi-fullscreen"></i>
                                </a>
                                <a href="portfolio-details-7.html">
                                    <i class="zmdi zmdi-link"></i>
                                </a>
                            </div>
                        </div>
                        <div class="project-title pl-30 ptb-30">
                            <a href="portfolio-details-7.html"><h4 class="mb-5">Portfolio Item Title</h4></a>
                            <p>Print, Branding</p>
                            <hr class="line">
                        </div>
                    </div>
                    <div class="grid-item work3d print web percent-33" style="position: absolute; left: 0px; top: 513px;">
                        <div class="single-portfolio overlay">
                            <img src="assets/img/portfolio/three/4.jpg" alt="MiniPo">
                            <div class="zoom-icon">
                                <a class="venobox vbox-item" data-gall="gall-img" href="assets/img/portfolio/three/4.jpg">
                                    <i class="zmdi zmdi-fullscreen"></i>
                                </a>
                                <a href="portfolio-details-7.html">
                                    <i class="zmdi zmdi-link"></i>
                                </a>
                            </div>
                        </div>
                        <div class="project-title pl-30 ptb-30">
                            <a href="portfolio-details-7.html"><h4 class="mb-5">Portfolio Item Title</h4></a>
                            <p>Print, Branding</p>
                            <hr class="line">
                        </div>
                    </div>
                    <div class="grid-item work3d design new percent-33" style="position: absolute; left: 396px; top: 513px;">
                        <div class="single-portfolio overlay">
                            <img src="assets/img/portfolio/three/5.jpg" alt="MiniPo">
                            <div class="zoom-icon">
                                <a class="venobox vbox-item" data-gall="gall-img" href="assets/img/portfolio/three/5.jpg">
                                    <i class="zmdi zmdi-fullscreen"></i>
                                </a>
                                <a href="portfolio-details-7.html">
                                    <i class="zmdi zmdi-link"></i>
                                </a>
                            </div>
                        </div>
                        <div class="project-title pl-30 ptb-30">
                            <a href="portfolio-details-7.html"><h4 class="mb-5">Portfolio Item Title</h4></a>
                            <p>Print, Branding</p>
                            <hr class="line">
                        </div>
                    </div>
                    <div class="grid-item work3d print design percent-33" style="position: absolute; left: 793px; top: 513px;">
                        <div class="single-portfolio overlay">
                            <img src="assets/img/portfolio/three/6.jpg" alt="MiniPo">
                            <div class="zoom-icon">
                                <a class="venobox vbox-item" data-gall="gall-img" href="assets/img/portfolio/three/6.jpg">
                                    <i class="zmdi zmdi-fullscreen"></i>
                                </a>
                                <a href="portfolio-details-7.html">
                                    <i class="zmdi zmdi-link"></i>
                                </a>
                            </div>
                        </div>
                        <div class="project-title pl-30 ptb-30">
                            <a href="portfolio-details-7.html"><h4 class="mb-5">Portfolio Item Title</h4></a>
                            <p>Print, Branding</p>
                            <hr class="line">
                        </div>
                    </div>
                    <div class="grid-item work3d print percent-33" style="position: absolute; left: 0px; top: 1027px;">
                        <div class="single-portfolio overlay">
                            <img src="assets/img/portfolio/three/7.jpg" alt="MiniPo">
                            <div class="zoom-icon">
                                <a class="venobox vbox-item" data-gall="gall-img" href="assets/img/portfolio/three/7.jpg">
                                    <i class="zmdi zmdi-fullscreen"></i>
                                </a>
                                <a href="portfolio-details-7.html">
                                    <i class="zmdi zmdi-link"></i>
                                </a>
                            </div>
                        </div>
                        <div class="project-title pl-30 ptb-30">
                            <a href="portfolio-details-7.html"><h4 class="mb-5">Portfolio Item Title</h4></a>
                            <p>Print, Branding</p>
                            <hr class="line">
                        </div>
                    </div>
                    <div class="grid-item web print new percent-33" style="position: absolute; left: 396px; top: 1027px;">
                        <div class="single-portfolio overlay">
                            <img src="assets/img/portfolio/three/8.jpg" alt="MiniPo">
                            <div class="zoom-icon">
                                <a class="venobox vbox-item" data-gall="gall-img" href="assets/img/portfolio/three/8.jpg">
                                    <i class="zmdi zmdi-fullscreen"></i>
                                </a>
                                <a href="portfolio-details-7.html">
                                    <i class="zmdi zmdi-link"></i>
                                </a>
                            </div>
                        </div>
                        <div class="project-title pl-30 ptb-30">
                            <a href="portfolio-details-7.html"><h4 class="mb-5">Portfolio Item Title</h4></a>
                            <p>Print, Branding</p>
                            <hr class="line">
                        </div>
                    </div>
                    <div class="grid-item work3d design new percent-33" style="position: absolute; left: 793px; top: 1027px;">
                        <div class="single-portfolio overlay">
                            <img src="assets/img/portfolio/three/9.jpg" alt="MiniPo">
                            <div class="zoom-icon">
                                <a class="venobox vbox-item" data-gall="gall-img" href="assets/img/portfolio/three/9.jpg">
                                    <i class="zmdi zmdi-fullscreen"></i>
                                </a>
                                <a href="portfolio-details-7.html">
                                    <i class="zmdi zmdi-link"></i>
                                </a>
                            </div>
                        </div>
                        <div class="project-title pl-30 ptb-30">
                            <a href="portfolio-details-7.html"><h4 class="mb-5">Portfolio Item Title</h4></a>
                            <p>Print, Branding</p>
                            <hr class="line">
                        </div>
                    </div>
                </div>
                <div class="view-all unset text-center">
                    <a class="btn mt-40" href="portfolio-3-style-4.html">View More <i class="zmdi zmdi-arrow-right"></i></a>
                </div>
            </div>
    </div>
</div>
